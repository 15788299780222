import { createSlice } from "@reduxjs/toolkit";

const misResultsSlice = createSlice({
  name: "resultsManagement",
  initialState: {
    resultsList: [],
    candidatesResults: [],
    specificCandidateResult: [],
    offlineResults: [],
    NOSWiseResults: [],
    NOSWiseOfflineBatchDetails: [],
    CandidateDetailsNOSWiseOffline: [],
  },

  reducers: {
    getResultsList: (state, { payload }) => {
      state.resultsList = payload;
    },
    getCandidatesResult: (state, { payload }) => {
      state.candidatesResults = payload;
    },
    getSpecificCandidateResult: (state, { payload }) => {
      state.specificCandidateResult = payload;
    },
    getOfflineResultsList: (state, { payload }) => {
      state.offlineResults = payload;
    },
    getNOSWiseResultsList: (state, { payload }) => {
      state.NOSWiseResults = payload;
    },
    getNOSWiseOfflineBatchDetails: (state, { payload }) => {
      state.NOSWiseOfflineBatchDetails = payload;
    },
    getCandidateDetailsNOSWiseOffline: (state, { payload }) => {
      state.CandidateDetailsNOSWiseOffline = payload;
    },
  },
});

export const {
  getResultsList,
  getCandidatesResult,
  getSpecificCandidateResult,
  getOfflineResultsList,
  getNOSWiseResultsList,
  getNOSWiseOfflineBatchDetails,
  getCandidateDetailsNOSWiseOffline,
} = misResultsSlice.actions;

export const misResultsSelector = (state) => state.superAdmin.resultsManagement;

const misResultsReducer = misResultsSlice.reducer;

export default misResultsReducer;
